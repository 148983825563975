module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-9956351-3"],"gtagConfig":{"link_attribution":true,"linker":{"domains":["birminghamairport.co.uk"],"accept_incoming":true}},"pluginConfig":{"head":true,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-multi-pixels/gatsby-browser.js'),
      options: {"0":{"dev":false,"alias":"bhx","pixelId":"1075015516024250","viewContent":false,"pageView":true},"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BHX Travel Portal","short_name":"BHX Travel Portal","start_url":"/","background_color":"#0E2D6D","theme_color":"#0E2D6D","display":"standalone","icon":"src/assets/images/favicon.svg","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"01c2fdd56d837770aaf8b50a0a440085"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
