import React from 'react'
import { PropTypes } from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Link from '~components/Link'
import styles from '~styles/components/social.module.scss'

const Social = (props) => {
  return (
    <div className={classNames(styles.social, props.className)}>
      <Link
        href="http://www.facebook.com/birminghamairport"
        aria-label="Facebook"
        className={classNames(styles.socialItem, 'icon iconSm')}
        track={{
          category: 'engagement',
          action: 'follow',
          label: 'Facebook'
        }}
      >
        <Sprite name="facebook" />
      </Link>

      <Link
        href="https://www.instagram.com/birminghamairport/"
        aria-label="Instagram"
        className={classNames(styles.socialItem, 'icon iconSm')}
        track={{
          category: 'engagement',
          action: 'follow',
          label: 'Instagram'
        }}
      >
        <Sprite name="instagram" />
      </Link>

      <Link
        href="https://twitter.com/bhx_official"
        aria-label="Twitter"
        className={classNames(styles.socialItem, 'icon iconSm')}
        track={{
          category: 'engagement',
          action: 'follow',
          label: 'Twitter'
        }}
      >
        <Sprite name="twitter" />
      </Link>
    </div>
  )
}

Social.propTypes = {
  className: PropTypes.string
}

export default Social
