import React from 'react'
import PropTypes from 'prop-types'
import { breakpoints } from '~scripts/helpers'

class MediaQuery extends React.Component {
  constructor () {
    super()
    this.mqHandler = this.toggleVisibility.bind(this)
    this.mq = null

    this.state = {
      visible: false
    }
  }

  componentDidMount () {
    if (this.props.breakpoint) {
      this.registerMediaQuery()
      this.toggleVisibility(this.mq)
    }
  }

  componentWillUnmount () {
    if (this.props.breakpoint) {
      this.mq.removeListener(this.mqHandler)
    }
  }

  registerMediaQuery () {
    const { type } = this.props
    const size = this.getBreakpoint()
    this.mq = window.matchMedia(`(${type}-width: ${size})`)
    this.mq.addListener(this.mqHandler)
  }

  getBreakpoint () {
    const { breakpoint, type } = this.props
    const size = breakpoints[breakpoint]

    if (type === 'max') {
      const value = parseFloat(size)
      return `${Math.abs(value - 0.0625)}em`
    }

    return size
  }

  toggleVisibility (e) {
    this.setState({
      visible: e.matches
    })
  }

  render () {
    return (
      <>
        {this.state.visible && (
          this.props.children
        )}
      </>
    )
  }
}

MediaQuery.propTypes = {
  breakpoint: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.any
}

MediaQuery.defaultProps = {
  type: 'min'
}

export default MediaQuery
