import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '~styles/components/arrow.module.scss'
import Sprite from '~components/Sprite'

class Arrow extends Component {
  scroll (e) {
    e.preventDefault()

    window.scrollTo({
      top: parseInt(this.props.offset),
      behavior: 'smooth'
    })
  }

  render () {
    return (
      <button
        onClick={this.scroll.bind(this)}
        className={classNames(styles.arrow, 'icon iconLg', this.props.className)}
        type="button"
        aria-label="Back to the top"
        title="Back to the top"
      >
        <span className={styles.icon}>
          <Sprite name="chevron" />
        </span>
      </button>
    )
  }
}

Arrow.propTypes = {
  className: PropTypes.string,
  offset: PropTypes.number
}

Arrow.defaultProps = {
  offset: 0
}

export default Arrow
