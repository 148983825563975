import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Sprite from '~components/Sprite'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import { connect } from 'react-redux'
import RichText from '~components/RichText'
import { toggleModal } from '~redux/actions/global'
import styles from '~styles/components/register.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

class Register extends Component {
  constructor () {
    super()
    this.formRef = React.createRef()
  }

  render () {
    return (
      <StaticQuery
        query={query} render={({ register }) => (
          <div className={classNames(styles.overlay)}>
            <section className={styles.modal}>
              <button
                className={styles.close}
                type="button"
                aria-label="Close"
                onClick={() => this.props.toggleModal(false)}
              >
                <span className={styles.cross}>
                  <Sprite name="crossThin" fill={true} />
                </span>
              </button>

              <span className={classNames(styles.icon, 'icon')}>
                <Sprite name="mail" fill={true} />
              </span>

              <div className={classNames(styles.content, 'grid-x grid-small-center')}>
                <div className="cell smedium-8 medium-6">
                  <h3 className={styles.title}>
                    {register.title}
                  </h3>

                  {register.text && (
                    <div className={classNames(styles.text, editorStyles.editor)}>
                      <RichText json={register.text.json} />
                    </div>
                  )}

                  <iframe
                    src="https://bhx-travel-portal-signup.s3.eu-west-1.amazonaws.com/index.html"
                    scrolling="no"
                    className={styles.form}
                    ref={this.formRef}
                  />
                </div>
              </div>
            </section>
          </div>
        )}
      />
    )
  }
}

Register.propTypes = {
  toggleModal: PropTypes.func,
  show: PropTypes.bool
}

export const query = graphql`
  query {
    register: contentfulSignup(slug: {eq: "register"}) {
      id
      title
      text {
        json
      }
    }
  }
`

export default connect(null, { toggleModal })(Register)
