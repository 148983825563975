const initialState = {
  windowWidth: 0,
  windowHeight: 0,
  modal: false,
  vaccinated: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'STORE_WINDOW_SIZE': {
      return {
        ...state,
        windowWidth: action.width,
        windowHeight: action.height
      }
    }
    case 'TOGGLE_MODAL': {
      const toggle = action.modal ? 'add' : 'remove'
      document.documentElement.classList[toggle]('overflow-hidden')

      return {
        ...state,
        modal: action.modal
      }
    }
    case 'TOGGLE_VACCINATION_STATUS' : {
      return {
        ...state,
        vaccinated: action.vaccinated
      }
    }
    default: {
      return state
    }
  }
}
