import { extendDefaultSettings, hasCallback } from '~scripts/helpers'

class Sticky {
  constructor (elem, options) {
    this.elem = elem
    this.domElem = elem
    this.config = extendDefaultSettings(this.defaults(), options)
    this.domElem && this.init()
  }

  defaults () {
    return {
      cssClass: 'active',
      onScroll: null,
      onInit: null,
      offset: 50
    }
  }

  init () {
    this.currentPos = 0
    this.start = false
    const onInit = this.config.onInit
    window.addEventListener('scroll', this.throttler.bind(this))
    this.toggleVisibility(this.domElem, this.config)
    hasCallback(onInit) && onInit.call(this, this.domElem)
  }

  throttler () {
    if (!this.start) {
      this.start = true
      window.requestAnimationFrame(this.onScrollPage.bind(this, this.config))
      this.start = false
    }
  }

  onScrollPage ({ onScroll }) {
    this.toggleVisibility(this.domElem, this.config)
    this.updatePosition()
  }

  toggleVisibility (elem, { onScroll, offset, cssClass }) {
    const documentTop = window.pageYOffset

    if (this.currentPos <= documentTop && documentTop >= this.getOffset(offset)) {
      elem.classList.add(cssClass)
      hasCallback(onScroll) && onScroll.call(this, this.domElem, false)
    } else {
      elem.classList.remove(cssClass)
      hasCallback(onScroll) && onScroll.call(this, this.domElem, true)
    }
  }

  updatePosition () {
    this.currentPos = window.pageYOffset
  }

  getOffset (offset) {
    if (isFinite(offset) && !isNaN(offset)) {
      return offset
    } else if (hasCallback(offset)) {
      return offset.call(this)
    }
  }
}
export default Sticky
