import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'
import classNames from 'classnames'
import styles from '~styles/components/readMore.module.scss'

class ReadMore extends Component {
  constructor (props) {
    super(props)

    this.state = {
      expanded: false,
      truncated: false
    }

    this.handleTruncate = this.handleTruncate.bind(this)
    this.toggleLines = this.toggleLines.bind(this)
  }

  handleTruncate (truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated
      })
    }
  }

  toggleLines (event) {
    event.preventDefault()

    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const {
      children,
      more,
      less,
      lines,
      className
    } = this.props

    const {
      expanded,
      truncated
    } = this.state

    return (
      <div className={classNames(styles.wrapper, className)}>
        <Truncate
          lines={!expanded && lines}
          onTruncate={this.handleTruncate}
          ellipsis={(
            <>
              ...
              {' '}
              <button
                type="button"
                className={styles.button}
                onClick={this.toggleLines}
              >
                {more}
              </button>
            </>
          )}
        >
          {children}
        </Truncate>

        {!truncated && expanded && (
          <button
            type="button"
            className={classNames(styles.button, styles.buttonExpanded)}
            onClick={this.toggleLines}
          >
            {less}
          </button>
        )}
      </div>
    )
  }
}

ReadMore.defaultProps = {
  lines: 3,
  more: 'Read more',
  less: 'Show less'
}

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  className: PropTypes.string
}

export default ReadMore
