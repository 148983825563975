import React from 'react'
import { Provider } from 'react-redux'
import Main from './src/layouts/Main'
import store from './src/redux/store'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <Provider store={store}>
      <Main>
        {element}
      </Main>
    </Provider>
  )
}
