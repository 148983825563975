import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

export default class Link extends Component {
  constructor () {
    super()

    this.state = {
      external: false
    }
  }

  componentDidMount () {
    this.setState({
      external: this.isExternalLink()
    })
  }

  prependDomain () {
    if (typeof window !== 'undefined') {
      const href = this.props.href
      const { host } = window.location

      if (!href.match('^(https|http)')) {
        const url = `https://${host}${href}`
        return url
      }
    }

    return this.props.href
  }

  isExternalLink () {
    try {
      if (typeof window !== 'undefined') {
        const href = this.prependDomain(this.props.href)
        const url = new URL(href)
        const currentUrl = new URL(window.location.href)
        return url.host !== currentUrl.host
      }
    } catch (e) {
      return false
    }
  }

  handleTracking () {
    const { track, fbTrack } = this.props
    track && this.trackEvent()
    fbTrack && this.trackFbEvent()

    if (this.isExternalLink()) {
      this.trackOutbound()
    }
  }

  trackEvent () {
    if (typeof window !== 'undefined' && window.gtag) {
      const { category, action, label } = this.props.track

      window.gtag('event', action, {
        event_category: category,
        event_label: label
      })
    }
  }

  trackFbEvent () {
    if (typeof window !== 'undefined' && window.fbq) {
      const { event, name } = this.props.fbTrack
      window.fbq(event, name)
    }
  }

  trackOutbound () {
    if (typeof window !== 'undefined' && window.gtag) {
      const { href } = this.props

      window.gtag('event', 'outbound_link', {
        event_category: 'engagement',
        event_label: href,
        transport_type: 'beacon',
        non_interaction: true
      })
    }
  }

  render () {
    const { fbTrack, track, ...props } = this.props

    return (
      <>
        {this.state.external && (
          <a
            {...props}
            href={this.props.href}
            className={this.props.className}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              this.handleTracking()
              this.props.onClick && this.props.onClick()
            }}
          >
            {this.props.text && this.props.text}
            {this.props.children}
          </a>
        )}

        {!this.state.external && (
          <GatsbyLink
            {...props}
            to={this.props.href}
            className={this.props.className}
            onClick={() => {
              this.handleTracking()
              this.props.onClick && this.props.onClick()
            }}
          >
            {this.props.text}
            {this.props.children}
          </GatsbyLink>
        )}
      </>
    )
  }
}

Link.propTypes = {
  href: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  track: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  fbTrack: PropTypes.shape({
    event: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}
