import React, { Component } from 'react'
import Sprite from '~components/Sprite'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import RichText from './RichText'
import classNames from 'classnames'
import Button from '~components/Button'
import { connect } from 'react-redux'
import { toggleModal } from '~redux/actions/global'
import styles from '~styles/components/newsletter.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

class Newsletter extends Component {
  render () {
    return (
      <StaticQuery
        query={query} render={({ newsletter }) => (
          <div className={classNames(
            styles.newsletter,
            this.props.className,
            'section section-newsletter'
          )}
          >
            <div className="grid-container">
              <div className={styles.inner}>
                <div className={classNames(styles.content, 'grid-x grid-small-center')}>
                  <div className="cell smedium-10 medium-8 large-5">
                    <span className={classNames(styles.icon, 'icon')}>
                      <Sprite name="mail" />
                    </span>

                    <h3 className={styles.title}>
                      {newsletter.title}
                    </h3>

                    {newsletter.description && (
                      <div className={classNames(styles.text, editorStyles.editor)}>
                        <RichText json={newsletter.description.json} />
                      </div>
                    )}

                    <Button
                      className={classNames(styles.cta)}
                      onClick={() => this.props.toggleModal(true)}
                      track={{
                        action: 'sign_up_intension',
                        category: 'engagement',
                        label: 'Footer'
                      }}
                      fbTrack={{
                        event: 'trackCustom',
                        name: 'CompleteRegistrationIntension'
                      }}
                    >
                      {newsletter.buttonLabel}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}

Newsletter.propTypes = {
  toggleModal: PropTypes.func,
  className: PropTypes.string
}

export const query = graphql`
  query {
    newsletter: contentfulNewsletter {
      id
      title
      description {
        json
      }
      buttonLabel
    }
  }
`

export default connect(null, { toggleModal })(Newsletter)
