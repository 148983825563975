import React, { Component } from 'react'
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PropTypes from 'prop-types'
import Link from '~components/Link'

export default class RichText extends Component {
  constructor (props) {
    super(props)

    this.richTextOptions = {
      renderMark: {
        [MARKS.BOLD]: this.renderBold,
        [MARKS.ITALIC]: this.renderItalic,
        [MARKS.UNDERLINE]: this.renderUnderline
      },
      renderNode: {
        [INLINES.HYPERLINK]: this.renderHyperlink,
        [BLOCKS.LIST_ITEM]: this.renderListItem
      }
    }
  }

  renderHyperlink (node) {
    return <Link href={node.data.uri} text={documentToReactComponents({ content: node.content })} />
  }

  renderBold (text) {
    return (
      <strong>
        {text}
      </strong>
    )
  }

  renderItalic (text) {
    return (
      <em>
        {text}
      </em>
    )
  }

  renderUnderline (text) {
    return (
      <span style={{ textDecoration: 'underline' }}>
        {text}
      </span>
    )
  }

  renderListItem (node, text) {
    return (
      <li>
        {text}
      </li>
    )
  }

  render () {
    return documentToReactComponents(this.props.json, this.richTextOptions)
  }
}

RichText.propTypes = {
  json: PropTypes.object
}
