import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '~components/Link'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import styles from '../../styles/layout/nav.module.scss'

class MenuItem extends Component {
  constructor (props) {
    super(props)
    this.ref = React.createRef()
  }

  render () {
    return (
      <li
        key={this.props.item.id}
        ref={this.ref}
        className={classNames({
          [styles.menuItemActive]: this.props.active,
          [styles.menuItem]: true
        },
        this.props.className
        )}
      >
        <Link
          href={this.props.item.link}
          onClick={this.props.onClick}
          className={styles.menuItemLink}
        >
          {this.props.active && (
            <span
              className={styles.arrow}
              aria-label="hidden"
            >
              <Sprite name="chevron" />
            </span>
          )}

          {this.props.item.title}
        </Link>
      </li>
    )
  }
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string
  }).isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default MenuItem
