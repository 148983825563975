/**
 * Extend Default Settings
 * Extends the default settings of an object.
 * Default properies will be overwritten if the same key matches.
 * This is a shallow extension only useful for simple (single level) objects.
 * @param {object} defaults
 * @param {object} settings
 */
export const extendDefaultSettings = (defaults, settings) => {
  for (var key in settings) {
    if (Object.prototype.hasOwnProperty.call(settings, key)) {
      defaults[key] = settings[key]
    }
  }

  return defaults
}

/**
 * Has Callback
 * Checks if object is a valid function.
 * @param {object} styleProperty
 * @returns {boolean}
 */
export const hasCallback = (callback) => {
  return callback && typeof callback === 'function'
}

/**
 * Summarise
 * Truncate a string
 * @param {string} text
 * @param {number} limit
 * @returns {string}
 */
export const summarise = (text, limit) => {
  if (text.length <= limit) {
    return text
  }

  return `${text.slice(0, limit)}...`
}

/**
 * Breakpoints
 */
export const breakpoints = {
  small: 0,
  xsmall: '23em',
  xxsmall: '25.625em',
  smedium: '30em',
  medium: '43.125em',
  large: '56.875em',
  xlarge: '79.6875em',
  page: '96.25em'
}

/**
 * Capitalise First Letter
 * @param {string} text
 * @returns {mixed}
 */
export const capitaliseFirstLetter = (text) => {
  if (text) {
    const words = text.split(' ')

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }

    return words.join(' ')
  }

  return null
}

/**
 * Group Array Objects by Key
 * Groups an array of objects by a specified key.
 * @param {array} list
 * @param {string} key
 * @returns {array}
 */
export const groupArrayObjectsByKey = (list, key) => {
  return list.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * Is Screen Size
 * @param {string} size
 * @returns {boolean}
 */
export function isScreenSize (size) {
  if (breakpoints[size] === undefined) {
    return false
  }

  return window.matchMedia(`(min-width: ${breakpoints[size]})`).matches
}

/**
 * Track Event
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @returns {void}
 */
export const trackEvent = (action, category, label) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label
    })
  }
}

/**
 * Track Signup Event
 * @returns {void}
 */
export const trackSignupEvent = () => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'sign_up', {
      method: 'Google'
    })
  }
}

/**
 * Track FB Event
 * @param {string} event
 * @param {string} name
 * @returns {void}
 */
export const trackFbEvent = (event, name) => {
  if (typeof window !== 'undefined' && window.fbq) {
    window.fbq(event, name)
  }
}

/**
 * URL Matches Keyword
 * Checks that a URL contains a particular keyword.
 * Optionally filter by different location types see
 * https://developer.mozilla.org/en-US/docs/Web/API/Location#properties
 * @param {string} regex
 * @param {string} type
 * @returns {boolean}
 */
export const urlMatchesKeyword = (regex, type = 'href') => {
  if (typeof window !== 'undefined') {
    const href = window.location[type]
    return href.match(regex)
  }

  return false
}

/**
 * Convert To Title Case
 * Converts a string into title case format.
 * @param {string} str
 * @returns {string}
 */
export const convertToTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

/**
 * Sort Array by Key
 * Sorts an array of values by key in acsending
 * or descending order.
 * @param {array} array
 * @param {string} key
 * @param {string} order
 * @returns {array}
 */
export const sortArraybyKey = (array, key, order = 'DESC') => (
  array.sort((a, b) => {
    if (order === 'ASC') {
      return a[key] > b[key] ? -1 : 1
    }

    return a[key] > b[key] ? 1 : -1
  })
)

/**
 * Normalise Accented Chars
 * Normalises accented characters.
 * @param {string} str
 * @returns {string}
 */
export const normaliseAccentedChars = (str) => (
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
)

/**
 * Get Pathname from URL
 * Returns the last segment of the url pathname
 * if available otherwise returns fallback string.
 * @param {string} fallback
 * @return {string}
 */
export const getPathnamefromUrl = (fallback = 'Homepage') => {
  if (typeof window !== 'undefined') {
    const { pathname } = window.location
    const segments = pathname.split('/')
    const slugs = segments.filter(segment => segment !== '')
    const slug = slugs[slugs.length - 1]

    if (slug) {
      return convertToTitleCase(slug.replace(/-/g, ' '))
    }

    return fallback
  }
}
