import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from '~styles/components/button.module.scss'

class Button extends Component {
  handleOnClick () {
    this.props.onClick && this.props.onClick()
    this.props.track && this.trackEvent()
    this.props.fbTrack && this.trackFbEvent()
  }

  trackEvent () {
    if (typeof window !== 'undefined' && window.gtag) {
      const { category, action, label } = this.props.track

      window.gtag('event', action, {
        event_category: category,
        event_label: label
      })
    }
  }

  trackFbEvent () {
    if (typeof window !== 'undefined' && window.fbq) {
      const { event, name } = this.props.fbTrack
      window.fbq(event, name)
    }
  }

  render () {
    return (
      <button
        type="button"
        className={classNames(styles.button, this.props.className)}
        onClick={() => this.handleOnClick()}
      >
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  track: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  fbTrack: PropTypes.shape({
    event: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

export default Button
