export const storeWindowSize = (width, height) => ({
  type: 'STORE_WINDOW_SIZE',
  width,
  height
})

export const toggleModal = (modal) => ({
  type: 'TOGGLE_MODAL',
  modal
})

export const toggleVaccinationStatus = (vaccinated) => ({
  type: 'TOGGLE_VACCINATION_STATUS',
  vaccinated
})
