/* eslint-disable no-new */
import './src/styles/global.scss'
import appWrapper from './app-wrapper'
import 'focus-visible'
import 'picturefill'
import Modernizr from 'modernizr'
import ObjectFitImages from 'object-fit-images'

export const wrapRootElement = appWrapper

if (!Modernizr.objectfit) {
  ObjectFitImages()
}
