import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Header from './partials/Header'
import Footer from './partials/Footer'
import Register from '~components/Register'
import Cookie from '~components/Cookie'
import Notices from './partials/Notices'
import { connect } from 'react-redux'
import { trackEvent, trackSignupEvent, trackFbEvent, urlMatchesKeyword, getPathnamefromUrl } from '~scripts/helpers'

class Main extends Component {
  constructor () {
    super()
    this.parkingHandler = this.trackParking.bind(this)
    this.testBookingHandler = this.trackTestBooking.bind(this)
  }

  componentDidMount () {
    this.trackSignups()
    document.addEventListener('click', this.parkingHandler)
    document.addEventListener('click', this.testBookingHandler)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.parkingHandler)
    document.removeEventListener('click', this.testBookingHandler)
  }

  trackSignups () {
    const regex = /\/thank-you/gi

    if (urlMatchesKeyword(regex)) {
      trackSignupEvent()
      trackFbEvent('track', 'CompleteRegistration')
    }
  }

  trackParking (e) {
    const el = e.target
    const regex = /(www\.)?birminghamairport\.co\.uk\/airport-parking/gi

    if (el.matches('a') && el.getAttribute('href').match(regex)) {
      trackEvent(
        'parking_booking_intension',
        'engagement',
        el.innerText
      )

      trackFbEvent('trackCustom', 'ParkingBookingIntension')
    }
  }

  trackTestBooking (e) {
    const el = e.target

    if (el.matches('a')) {
      const href = el.getAttribute('href')
      const regex = /(www\.)?birminghamairport\.co\.uk\/covid-19-testing/gi
      const label = getPathnamefromUrl()

      if (href.match(regex)) {
        trackEvent('test_booking_intension', 'engagement', label)
        trackFbEvent('trackCustom', 'TestBookingIntension')
      }
    }
  }

  render () {
    return (
      <>
        <Notices />
        <Header />

        <main role="main">
          {this.props.children}
        </main>

        <Footer />
        <Cookie />

        {this.props.modal && (
          <Register />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  modal: state.global.modal
})

Main.propTypes = {
  modal: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  data: PropTypes.object
}

export default connect(mapStateToProps)(Main)
