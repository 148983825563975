/* eslint-disable no-new */
import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from '~components/Link'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MenuItem from './MenuItem'
import Sprite from '~components/Sprite'
import Sticky from '~scripts/Sticky'
import Button from '~components/Button'
import Social from '~components/Social'
import { connect } from 'react-redux'
import { toggleModal } from '~redux/actions/global'
import MediaQuery from '~components/helpers/MediaQuery'
import styles from '../../styles/layout/header.module.scss'
import navStyles from '../../styles/layout/nav.module.scss'

class Header extends Component {
  constructor (props) {
    super(props)
    this.headerRef = React.createRef()

    this.state = {
      activeItem: 'nav-home',
      open: false
    }
  }

  componentDidMount () {
    new Sticky(this.headerRef.current, {
      cssClass: styles.disabled
    })
  }

  showModal () {
    this.props.toggleModal(true)
  }

  toggleMenu (e) {
    this.setState({
      open: !this.state.open
    })

    const toggleClass = this.state.open ? 'add' : 'remove'
    document.documentElement.classList[toggleClass]('nav-open')
  }

  setActiveItem (activeItem) {
    this.setState({
      activeItem
    })
  }

  render () {
    const _render = ({ contentfulMenu, links }) => {
      return (
        <header className={styles.header} ref={this.headerRef}>
          <div className={classNames('grid-container', styles.inner)}>
            <div className={classNames(styles.links)}>
              <button
                type="button"
                title="Toggle menu"
                aria-label="Toggle menu"
                className={styles.toggle}
                onClick={() => this.toggleMenu()}
                onFocus={() => this.toggleMenu()}
              >
                <span className={styles.lines}></span>
              </button>

              <Link
                href="/"
                className={classNames(styles.icon)}
                aria-label="Birmingham Airport"
              >
                <Sprite name="circles" />
              </Link>
            </div>

            <Link
              href="/"
              className={classNames(styles.logo, 'show-for-large')}
              aria-label="Birmingham Airport"
            >
              <Sprite name="logo" />
            </Link>

            <nav className={classNames(navStyles.nav)}>
              <ul className={navStyles.menu}>
                <MediaQuery
                  type="max"
                  breakpoint="large"
                >
                  <MenuItem
                    key="nav-item-home"
                    item={{
                      id: 'nav-item-home',
                      title: 'Home',
                      link: '/'
                    }}
                    active={this.state.activeItem === 'nav-home'}
                    onClick={() => {
                      this.toggleMenu()
                      this.setActiveItem('nav-home')
                    }}
                  />
                </MediaQuery>

                {contentfulMenu.items.map(item => (
                  <MenuItem
                    key={item.id}
                    item={item}
                    active={this.state.activeItem === item.id}
                    onClick={() => {
                      this.toggleMenu()
                      this.setActiveItem(item.id)
                    }}
                  />
                ))}
              </ul>

              <MediaQuery
                type="max"
                breakpoint="large"
              >
                <ul className={navStyles.subMenu}>
                  {links.items.map(item => (
                    <li
                      key={item.id}
                      className={navStyles.subMenuItem}
                    >
                      <Link
                        href={item.link}
                        className={classNames(
                          navStyles.subMenuLink,
                          { [navStyles.subMenuLinkActive]: this.state.activeItem === item.id }
                        )}
                        onClick={() => {
                          this.toggleMenu()
                          this.setActiveItem(item.id)
                        }}
                        text={item.title}
                      />
                    </li>
                  ))}
                </ul>

                <div className={navStyles.bottom}>
                  <Social className={navStyles.social} />
                </div>
              </MediaQuery>
            </nav>

            <Button
              className={styles.button}
              onClick={() => this.props.toggleModal(true)}
              track={{
                action: 'sign_up_intension',
                category: 'engagement',
                label: 'Header'
              }}
              fbTrack={{
                event: 'trackCustom',
                name: 'CompleteRegistrationIntension'
              }}
            >
              <span>
                Sign up
                {' '}
                <span className="show-for-xlarge">
                  for updates
                </span>
              </span>
            </Button>
          </div>
        </header>
      )
    }

    return (
      <StaticQuery query={query} render={_render} />
    )
  }
}

Header.propTypes = {
  toggleModal: PropTypes.func
}

export const query = graphql`
  query {
    contentfulMenu(name: {eq: "main-menu"}) {
      items {
        id
        title
        link
      }
    }
    links: contentfulMenu(name: {eq: "links"}) {
      items {
        id
        title
        link
      }
    }
  }
`
export default connect(null, { toggleModal })(Header)
