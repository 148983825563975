import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Link from '~components/Link'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Sprite from '~components/Sprite'
import Arrow from '~components/Arrow'
import Social from '~components/Social'
import Newsletter from '~components/Newsletter'
import styles from '~styles/layout/footer.module.scss'
import arrowStyles from '~styles/components/arrow.module.scss'

class Footer extends Component {
  scroll (e) {
    e.preventDefault()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  getYear () {
    const date = new Date()
    return date.getFullYear()
  }

  render () {
    return (
      <StaticQuery
        query={query} render={({ links, footerMenu }) => (
          <footer className={styles.footer} role="contentinfo">
            <Newsletter
              className={styles.newsletter}
            />

            <div className={styles.bottom}>
              <div className={classNames('grid-container')}>
                <div className={styles.inner}>
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-4">
                      <a
                        href="/"
                        className={styles.logo}
                        aria-label="Birmingham Airport - Here for your journey"
                      >
                        <Sprite name="logoStrapline" fill={true} />
                      </a>
                    </div>

                    <div className="cell large-6 xlarge-offset-1">
                      <nav className="grid-x grid-margin-x grid-large-right">
                        {links.items && (
                          <div className={classNames(styles.navItem, styles.row, 'cell large-5')}>
                            <h4 className={classNames(styles.navTitle)}>
                              {links.title}
                            </h4>

                            <ul className={classNames(styles.menu)}>
                              {links.items.map((item, index) => (
                                <li className={styles.menuItem} key={index}>
                                  <Link href={item.link} className={styles.menuLink}>
                                    {item.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </nav>
                    </div>

                    <div className={classNames(styles.social, 'cell')}>
                      <Social />
                    </div>

                    <div className={classNames('cell show-for-large', styles.middle)}>
                      <p className={classNames(styles.copyright)}>
                        <span className={styles.copy}>
                          &copy;
                        </span>
                        {' '}
                        {this.getYear()}
                        {' '}
                        Birmingham Airport Ltd. Company Number 2078273. Place of Registration UK.
                      </p>

                      <p className={classNames(styles.copyright)}>
                        Registered office: Diamond House, Birmingham Airport, Birmingham B26 3QJ VAT GB 687 9037 77
                      </p>
                    </div>

                    <div className={classNames('cell', styles.footnote)}>
                      {footerMenu.items && (
                        <ul className={styles.subMenu}>
                          {footerMenu.items.map((item, index) => (
                            <li className={styles.subMenuItem} key={index}>
                              <Link href={item.link} className={styles.subMenuLink}>
                                {item.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}

                      <a href="https://effectdigital.com/" target="_blank" rel="noreferrer" className={classNames(styles.siteBy, 'show-for-large')}>
                        Site by: effect digital
                      </a>
                    </div>

                    <Arrow className={classNames(
                      styles.arrow,
                      arrowStyles.white,
                      arrowStyles.up
                    )}
                    />

                    <div className="cell hide-for-large">
                      <p className={styles.copyright}>
                        &copy;
                        {' '}
                        {this.getYear()}
                        {' '}
                        Birmingham Airport Ltd. Company Number 2078273. Place of Registration UK.
                        <br />
                        <br />
                        Registered office: Diamond House, Birmingham Airport, Birmingham B26 3QJ VAT GB 687 9037 77
                        <br />
                        <br />
                        <a href="https://effectdigital.com/" target="_blank" rel="noreferrer" className={styles.siteBy}>
                          Site by: effect digital
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      />
    )
  }
}

Footer.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    links: contentfulMenu(name: {eq: "links"}) {
      id
      title
      items {
        id
        title
        link
      }
    }
    footerMenu: contentfulMenu(name: {eq: "footer-menu"}) {
      id
      title
      items {
        id
        title
        link
      }
    }
  }
`

export default Footer
