import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Sprite from '~components/Sprite'
import RichText from '~components/RichText'
import classNames from 'classnames'
import styles from '../../styles/layout/notices.module.scss'
import editorStyles from '~styles/components/editor.module.scss'

export default class Notices extends Component {
  constructor (props) {
    super(props)

    this.cookieName = 'seenNotices'

    this.state = {
      hiddenNotices: Cookies.getJSON(this.cookieName) || []
    }
  }

  close (id) {
    const hiddenNotices = [...this.state.hiddenNotices, id].filter((n, i, a) => a.indexOf(n) === i)

    this.setState({
      hiddenNotices
    })

    Cookies.set(this.cookieName, hiddenNotices, { expires: 7 })
  }

  onExit (node) {
    node.style.height = `${node.clientHeight}px`
  }

  render () {
    return (
      <StaticQuery
        query={query} render={({ allContentfulNotice }) => (
          <TransitionGroup>
            {allContentfulNotice.nodes.filter(notice => this.state.hiddenNotices.indexOf(notice.id) === -1).map(notice => (
              <CSSTransition
                key={notice.id}
                timeout={300}
                classNames="fade"
                onExit={this.onExit.bind(this)}
              >
                <div className={styles.notice}>
                  <div className="grid-container">
                    <div className={classNames(styles.content, 'grid-x grid-margin-x')}>
                      {notice.text && (
                        <div className={classNames(styles.row, 'cell auto')}>
                          <div className={classNames(editorStyles.editor, styles.text)}>
                            <RichText json={notice.text.json} />
                          </div>
                        </div>
                      )}

                      <div className={classNames(styles.row, 'cell shrink')}>
                        <button className={styles.close} type="button" onClick={this.close.bind(this, notice.id)} aria-label="Close">
                          <span className={styles.icon}>
                            <Sprite name="cross" fill={true} />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
      />
    )
  }
}

export const query = graphql`
  query {
    allContentfulNotice {
      nodes {
        id
        text {
          json
        }
      }
    }
  }
`
