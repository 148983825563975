import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql, StaticQuery } from 'gatsby'
import Sprite from '~components/Sprite'
import ReadMore from '~components/ReadMore'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styles from '~styles/components/cookie.module.scss'

class Cookie extends Component {
  constructor () {
    super()

    this.state = {
      accepted: true
    }
  }

  componentDidMount () {
    this.setState({
      accepted: this.hasAccepted()
    })
  }

  hasAccepted () {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('bhx-accept-cookie') !== null
    }
  }

  handleOnClick () {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('bhx-accept-cookie', 1)
    }

    this.setState({
      accepted: true
    })
  }

  render () {
    return (
      <StaticQuery
        query={query} render={({ cookie }) => (
          <div className={classNames(styles.wrapper, { [styles.active]: !this.state.accepted })}>
            <div className={classNames('grid-container', styles.inner)}>
              <ReadMore
                lines={1}
                className={styles.message}
              >
                {documentToReactComponents(cookie.text.json)}
              </ReadMore>

              <button className={styles.close} type="button" aria-label="Close cookie policy" onClick={() => this.handleOnClick()}>
                <span className={classNames('icon', styles.icon)} aria-hidden>
                  <Sprite name="cross" fill={true} />
                </span>
              </button>
            </div>
          </div>
        )}
      />
    )
  }
}

Cookie.propTypes = {
  text: PropTypes.string
}

export const query = graphql`
  query {
    cookie: contentfulCookie {
      id
      title
      text {
        json
      }
    }
  }
`

export default Cookie
